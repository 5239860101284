import React, { useState, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { Container } from 'react-bootstrap';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Link } from 'gatsby';
import { ReactSVG } from 'react-svg';

import GlobalContext from '../../context/GlobalContext';
import Offcanvas from '../Offcanvas';
import NestedMenu from '../NestedMenu';
import DropdownMenu from '../DropdownMenu';
import { device } from '../../utils';
// import ThemeSwitch from "../ThemeSwitch";
import Logo, { SmallLogo } from '../Logo/';
import menu from '/src/assets/svg/ui/menu.svg';
//import Logo from '../../components/Logo';

// https://stackoverflow.com/questions/34136588/css3-animation-pendulum-effect
const chairAnimation = keyframes`
  from {transform: rotate(0deg); animation-timing-function: cubic-bezier(0.1, 0.3, 0.3, 1);}
  25% {transform: rotate(5deg); animation-timing-function: cubic-bezier(0.7, 0.0, 0.9, 0.7);}
  50% {transform: rotate(0deg); animation-timing-function: cubic-bezier(0.1, 0.3, 0.3, 1);}
  75% {transform: rotate(-5deg); animation-timing-function: cubic-bezier(0.7, 0.0, 0.9, 0.7);}
  to {transform: rotate(0deg);}
`;

const NavContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;

  @media ${device.md} {
    justify-content: space-between;
  }
`;

const Nav = styled.nav`
  width: 100%;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
`;

const SiteHeader = styled.header`
  padding: 0 2rem;
  position: fixed !important;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;

  &.reveal-header {
    background: ${({ theme }) => theme.colors.mainBackgroundColor};
  }

  @media ${device.md} {
    position: fixed !important;
    transition: transform 400ms ease;

    &.scrolling {
      transform: translate(-50%, -100%);
    }

    &.reveal-header {
      ${'' /* padding: 0 1rem; */}
      transform: translate(-50%, 0);
      z-index: 9999;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .navbar {
    height: 68px;
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 165px;
    flex-flow: row nowrap;
    justify-content: flex-end;

    @media ${device.lg} {
      padding-left: 260px;
    }
  }

  &.reveal-header .navbar {
    height: 60px;
    padding-left: 142px;
  }

  .big-logo {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity 500ms ease, transform 500ms ease;
  }

  .big-logo-hide {
    opacity: 0;
  }

  .big-logo:after {
    content: '';
    width: 50vw;
    height: 2px;
    background: hsla(50, 100%, 50%, 1);
    /* background:  hsla(225, 50%, 50%, 1); */
    transform: rotate(-32deg);
    transform-origin: top right;
    position: absolute;
    right: 0;
    top: -6px;

    @media ${device.md} {
      width: 40vw;
      right: 0;
      top: -6px;
      height: 3px;
    }

    @media ${device.lg} {
      top: -11px;
      height: 4px;
    }
  }

  &.scrolling .big-logo {
    opacity: 0;
    transform: translateY(-100%);
  }

  &.reveal-header .big-logo {
    opacity: 0;
    pointer-events: none;
  }

  .big-logo-chair {
    cursor: pointer;
  }

  .chair-swing-animation {
    transform-origin: top center;
    animation: 1.5s ${chairAnimation} infinite; /* 10s delay */
  }

  .small-logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 122px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1000ms ease;
  }

  &.reveal-header .small-logo {
    opacity: 1;
    background: ${({ theme }) => theme.colors.mainBackgroundColor};
    // box-shadow: 0 0 20px 10px ${({ theme }) => theme.colors.mainBackgroundColor};
  }

  .navbar-nav {
    gap: 0 20px;
  }

  .navbar-toggler {
    cursor: pointer;
    border: none;
    background: ${({ theme }) => theme.colors.mainBackgroundColor};
    padding: 6px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;

    svg {
      display: block;
    }

    @media ${device.md} {
      display: none;
    }
  }
`;

const ToggleButton = styled.button`
  /* color: ${({ dark, theme }) => theme.colors.front}!important;
  border-color: ${({ dark, theme }) => theme.colors.front}!important; */
`;

const Menu = styled.ul`
  @media ${device.md} {
    display: flex;
    justify-content: flex-end;
  }

  .dropdown-toggle {
    cursor: pointer;
  }

  > li {
    > .nav-link {
      color: ${({ theme }) => theme.colors.veryDarkBlue};
      position: relative;
      padding: 0;

      &:after {
        background: ${({ theme }) => theme.colors.veryDarkBlue};
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        height: 1px;
        opacity: 0;
        width: 0;
        transition:
          opacity 250ms ease,
          width 250ms ease;
      }

      @media ${device.md} {
        transition: 0.4s;
        align-items: center;
        display: inline-flex;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-transform: lowercase;
      }
    }

    &.nav-item:hover > .nav-link {
      color: var(--golden-gate-bridge);
    }

    &.nav-item:hover > .nav-link:after,
    &.nav-item.active > .nav-link:after {
      width: 100%;
      opacity: 1;
      background: var(--golden-gate-bridge);
    }
  }

  .nav-item.dropdown {
    @media ${device.md} {
      position: relative;
      z-index: 99;
    }

    &:hover {
      > .menu-dropdown {
        @media ${device.md} {
          top: 90%;
          right: 0;
          opacity: 1;
          pointer-events: visible;
        }
      }
    }
  }
`;

const Header = ({ isDark }) => {
  const gContext = useContext(GlobalContext);

  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);
  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (currPos.y < -300) {
      setShowReveal(true);
    } else {
      setShowReveal(false);
    }
  });

  return (
    <>
      <SiteHeader //
        className={`sticky-header ${showScrolling ? 'scrolling' : ''} ${showReveal ? 'reveal-header' : ''}`}
        dark={isDark ? 1 : 0}
      >
        <NavContainer fluid>
          <Nav className="navbar site-navbar navbar-expand-lg">
            <div
              className={`big-logo ${gContext.showHomeHeroText === false ? 'big-logo-hide' : ''}`}
            >
              <Logo className="big-logo-chair" />
            </div>

            <div className="small-logo">
              <SmallLogo color={isDark ? 'light' : 'dark'} />
            </div>

            <div className="collapse navbar-collapse">
              <div className="navbar-nav ml-lg-auto mr-3">
                <Menu
                  className="navbar-nav d-none d-lg-flex"
                  dark={isDark ? 1 : 0}
                >
                  <li className="nav-item">
                    <Link to="/" className="nav-link">
                      home
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/how-it-works">
                      how it works
                    </Link>
                  </li>

                  <DropdownMenu />

                  <li className="nav-item">
                    <Link className="nav-link" to="/about">
                      about
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/contact">
                      contact
                    </Link>
                  </li>
                </Menu>
              </div>
            </div>

            <ToggleButton
              className={`navbar-toggler btn-close-off-canvas ml-3 ${
                gContext.visibleOffCanvas ? 'collapsed' : ''
              }`}
              type="button"
              data-toggle="collapse"
              data-target="#mobile-menu"
              aria-controls="mobile-menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={gContext.toggleOffCanvas}
              dark={isDark ? 1 : 0}
            >
              <ReactSVG src={menu} />
            </ToggleButton>
          </Nav>
          {/* <ThemeSwitch isDark={ isDark } /> */}
        </NavContainer>
      </SiteHeader>

      <Offcanvas
        show={gContext.visibleOffCanvas}
        onHideOffcanvas={gContext.toggleOffCanvas}
      >
        <NestedMenu />
      </Offcanvas>
    </>
  );
};
export default Header;
